<template>
  <div>
    <div v-if="item">
      <v-menu right offset-x :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="(item.comments && item.comments.length> 0) || comments.length>0?'primary':''"
            v-on="on"
            class="mb-0 pb-0"
            v-bind="attrs"
            small
            icon @click="getComments(item.id)"
          >
            <v-icon small>forum</v-icon>
          </v-btn>
        </template>
        <v-card width="20vw" style="overflow-y: hidden">
          <v-subheader>{{ item.document }}</v-subheader>
          <v-divider v-if="!loadingComments"></v-divider>
          <v-progress-linear
            indeterminate
            style="height: 1px"
            v-if="loadingComments"
          ></v-progress-linear>
          <v-card height="40vh" width="inherit" style="overflow-y: auto" flat>
            <v-list>
              <!-- No Comments -->
              <v-list-item
                v-if="
                  !loadingComments &&
                  (!comments || comments.length === 0)
                "
              >
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey">No comments.</span>
                </v-list-item-content>
              </v-list-item>

              <!-- Display Comments -->
              <v-list-item
                v-else
                v-for="(comment, index) in comments"
                :key="index"
                :id="'comment_' + index"
                class="py-0 my-1"
              >
                <!-- Other Users' Comments -->
                <v-list-item-avatar
                  v-if="
                    comment.createdBy &&
                    comment.createdBy.id !== $store.state.user.id
                  "
                >
                  <v-tooltip top v-if="comment.createdBy">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        color="secondary"
                        size="48"
                        class="mt-0 pt-0"
                      >
                        <span
                          v-if="comment.createdBy && !comment.createdBy.avatar"
                          class="primaryText--text text-h5"
                        >
                          {{
                            $Format.avatarAbbreviation(
                              comment.createdBy?.firstname +
                                " " +
                                comment.createdBy?.surname
                            )
                          }}
                        </span>
                        <img
                          v-else-if="comment.createdBy?.avatar"
                          :src="comment.createdBy?.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px"
                      >{{ comment.createdBy?.firstname }}
                      {{ comment.createdBy?.surname }}</span
                    >
                  </v-tooltip>
                </v-list-item-avatar>

                <!-- Current User's Comments -->
                <v-list-item-content
                  :class="
                    comment.createdBy &&
                    comment.createdBy.id === $store.state.user.id
                      ? 'text-right'
                      : 'text-left'
                  "
                >
                  <v-list-item-title class="text-wrap">
                    {{ comment.comment }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <i style="font-size: 12px; color: grey">
                      {{ comment.commentType }}
                    </i>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <i style="font-size: 12px; color: grey">{{
                      $Format.formatDate(comment.createdAt).dateTime
                    }}</i>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Current User's Avatar on Right -->
                <v-list-item-avatar
                  v-if="
                    comment.createdBy &&
                    comment.createdBy.id === $store.state.user.id
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        color="secondary"
                        size="48"
                        class="mt-0 pt-0"
                      >
                        <span
                          v-if="!comment.createdBy.avatar"
                          class="primaryText--text text-h5"
                        >
                          {{
                            $Format.avatarAbbreviation(
                              comment.createdBy.firstname +
                                " " +
                                comment.createdBy.surname
                            )
                          }}
                        </span>
                        <img
                          v-else
                          :src="comment.createdBy.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px"
                      >{{ comment.createdBy.firstname }}
                      {{ comment.createdBy.surname }}</span
                    >
                  </v-tooltip>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card
            flat
            width="inherit"
            style="
              background-color: var(--v-greyRaised-base) !important;
              overflow-y: hidden;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-content: center;
              "
              class="mb-2"
            >
              <v-chip
                small
                outlined
                class="mx-3"
                @click="commentItem.commentType = 'General'"
                :color="commentItem.commentType == 'General' ? 'tertiary' : ''"
                >General</v-chip
              >
              <v-chip
                small
                outlined
                class="mx-3"
                @click="commentItem.commentType = 'Invoice'"
                :color="commentItem.commentType == 'Invoice' ? 'tertiary' : ''"
                >Invoice</v-chip
              >
              <v-chip
                small
                outlined
                class="mx-3"
                @click="commentItem.commentType = 'Query'"
                :color="commentItem.commentType == 'Query' ? 'tertiary' : ''"
                >Query</v-chip
              >
            </div>
            <div style="display: flex; align-items: center">
              <v-textarea
                class="ml-2"
                no-resize
                hide-details
                outlined
                autofocus
                height="7vh"
                style="width: 80%; contain: content"
                placeholder="Send a message"
                v-model="commentItem.comment"
                :value="commentItem.comment"
              >
              </v-textarea>
              <v-btn
                :loading="savingComment"
                icon
                class="ml-3 mr-2"
                @click="saveComment(item)"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-card>
      </v-menu>
      <v-chip
        v-if="item.status"
        small
        class="primaryText--text"
        outlined
        style="border: none"
      >
        <span :class="item.status == 'Rejected' ? 'rejected' : ''">{{
          item.status
        }}</span>
      </v-chip>
    </div>
    <div v-else-if="group">
      <v-chip small class="primaryText--text" outlined style="border: none">
        <span :class="group == 'Rejected' ? 'rejected' : ''">{{
          group
        }}</span>
      </v-chip>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    commentItem: {
      comment: "",
      commentType: "General",
    },
    comments: [],
    loadingComments: false,
    item: null,
    group: null,
    savingComment: false,
  }),
  mounted(){
    this.item = this.params.data;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    async getComments(id) {
            // console.log('getShipmentDocumentLog called with ID:', id);
            this.loadingComments = true;

            try {
                let comments = await this.$API.getShipmentDocumentTypeComments(id);
                this.comments = JSON.parse(JSON.stringify(comments));
                console.log('Processed comments data:', comments);

                // if (comments) {
                //     this.files = this.files.map(file => {
                //         if (file.id === id) {
                //             // console.log('Assigning comments to file with ID:', file.id);
                //             file.comments = comments;
                //             // console.log('Updated file:', file);
                //         }
                //         return file;
                //     });
                // }
            } catch (error) {
                console.error('Error fetching comments:', error);
            } finally {
                this.loadingComments = false;
                console.log('Loading comments set to false');
            }
        },
    async saveComment() {
            this.savingComment = true
            if (this.commentItem && !this.commentItem.commentType) {
                this.commentItem.commentType = 'Query'
            }
            let comment = {
                shipmentDocumentTypeId: this.item.id,
                comment: this.commentItem.comment,
                commentType: this.commentItem.commentType,
                shipmentId: this.item.shipmentId,
                createdById: this.$store.state.user.id,
            }
            let res = await this.$API.addShipmentDocumentTypeComment(comment)
            if(res) {
                comment.createdAt = res.createdAt
                comment.user = this.$store.state.user;
                comment.shipmentDocumentTypeComment = {
                    comment: this.commentItem.comment,
                    commentType: this.commentItem.commentType,
                }
                this.comments.push(comment)
            }   else {
                this.$message({
                    type: "error",
                    message: `Message could not go through. Please check your connection and try again.`,
                });
            }
            this.commentItem = {}
            this.savingComment = false
            },
  }
};
</script>